import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  language = 'es';
  defaultSrcImage: string = "./assets/img/logo-mcas.png"
  srcImage: string
  website = 'movired.com';

  constructor(private translate: TranslateService) {
    this.defaultSrcImage = "./assets/img/logo-mln.png"
    this.srcImage = this.defaultSrcImage;
  }

  changeLocale(language) {
    this.translate.use(language);
    this.language = language;
    // We need to do this conversion because english flag icon is 'gb', not 'en'
    if (language === 'en') {
      this.language = 'gb';
    }
  }


  updateImageToDefault(){
    this.srcImage = this.defaultSrcImage;
  }


}
