import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Request } from 'src/app/models/request.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private API_URL = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getRequestsByHashCode(tenantId: string, code: string): Observable<Request[]> {
    const headers  = new HttpHeaders({
      'X-TenantID': tenantId,
    });

    return this.http.get<Request[]>(this.API_URL + 'basic/hashcode/' + code, {headers: headers});
  }
}
