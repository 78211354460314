import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { version } from 'package.json';

@Component({
  selector: 'app-about-popup',
  templateUrl: './about-popup.component.html',
  styleUrls: ['./about-popup.component.css']
})

/**
* Esta clase esta pensada para generar "about popups" de manera dinámica,
* pudiendo indicarle:
* 
* projectName : String Nombre del proyecto
* version : String Version del proyecto
* logoUrl : String Url de la imagen principal del popUp
* company : String nombre de la compañia
* website : String sitio web.
* 
* Ejemplo. <app-pop-up title="About" nameProyect="Pecsa" version="0.1" 
* urlImg="./assets/movired/logo.svg" webSite="www.movired.com" 
* company="Movired 2000 SL"></app-pop-up>

*/
export class AboutPopupComponent {

  constructor(private router: Router) { }

  @Input() projectName: string;
  version: string = version;
  @Input() logoUrl: string;
  @Input() company: string;
  @Input() website: string;

  display: boolean = false;

  showDialog() {
    this.display = true;
  }

  goToDocuments() {
    this.router.navigate(['document']);
  }
}