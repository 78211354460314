import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RequestListComponent } from './modules/request/request-list/request-list.component';
import { SurveyComponent } from './components/survey/survey/survey.component';

const routes: Routes = [
  { path: ':company/:hashCode', component: RequestListComponent, pathMatch: 'full' },
  { path: 'survey/:company/:satisfaction/:idIncidence/:type/:username', component: SurveyComponent, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
  { path: '', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
