import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestListComponent } from './request-list/request-list.component';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { MoviredModule } from 'src/app/components/movired.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ButtonModule } from 'primeng/button';
import { NgxPrintModule } from 'ngx-print';
import { RequestService } from 'src/app/services/request/request.service';


@NgModule({
  declarations: [RequestListComponent],
  imports: [
    CommonModule,
    TableModule,
    TranslateModule.forChild(),
    DropdownModule,
    MoviredModule,
    NgxQRCodeModule,
    ButtonModule,
    NgxPrintModule
  ],
  providers: [
    RequestService
  ]
})
export class RequestModule { }
