import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { AboutPopupComponent } from './about-popup/about-popup.component';

// Imports of primeNG
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToolbarModule } from 'primeng/toolbar';
import { GalleriaModule } from 'primeng/galleria';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { SurveyComponent } from './survey/survey/survey.component';
@NgModule({
  declarations: [
    HeaderComponent,
    AboutPopupComponent,
    NotFoundComponent,
    SurveyComponent
  ],
  exports: [
    HeaderComponent,
    AboutPopupComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    PanelMenuModule,
    DialogModule,
    ProgressSpinnerModule,
    ToolbarModule,
    GalleriaModule,
    ConfirmDialogModule,
    TranslateModule.forChild(),
    DropdownModule,
  ]
})
export class MoviredModule { }
