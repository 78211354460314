import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  private API_URL = environment.baseUrl;

  constructor(private http : HttpClient) { }

  saveSurvey(tenantId: string, satisfaction: number, idIncidence: number, type: string, username: string): Observable<any> {

    const headers  = new HttpHeaders({
      'X-TenantID': tenantId
    });
    return this.http.get<any>(this.API_URL + 'open-api/survey/'+satisfaction+"/"+idIncidence+"/"+type+"/"+username , {headers: headers});

  }
}
