import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { House } from 'src/app/models/house.model';
import { MessageCustomService } from 'src/app/services/messageCustom/message-custom.service';
import { Table } from 'primeng/table';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { DatePipe } from '@angular/common';
import { RequestService } from 'src/app/services/request/request.service';
import { Request } from 'src/app/models/request.model';


@Component({
  selector: "app-request-list",
  templateUrl: "./request-list.component.html",
  styleUrls: ["./request-list.component.css"],
})
export class RequestListComponent implements OnInit {
  @ViewChild("requestListTable") private requestListTable: Table;
  colsRequest: {
    field: string;
    header: string;
    display: boolean;
    order: boolean;
    ellipsis?: boolean;
    width: string;
  }[] = [
    { field: "requestType", header: "REQUEST.REQUEST_TYPE", display: true, order: true, width: '10%' },
    { field: "location", header: "REQUEST.LOCATION", display: true, order: true, width: '10%' },
    { field: "materialFamily", header: "REQUEST.MATERIAL_FAMILY", display: true,  order: true, width: '15%' },
    { field: "reference", header: "GENERICS.REFERENCE", display: true, order: true, width: '15%' },
    { field: "description", header: "REQUEST.DESCRIPTION", display: true, order: false, width: '50%' },
  ];

  stringToTranslate: string[] = [
    "GENERICS.NOT_AVAILABLE_ABBREVIATION",
    "GENERICS.HOUSE",
    "HOUSE.NUMBER",
  ];
  requestList: Request[];
  totalRecords: number;
  house: House;
  hashCode: string;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrValue: string;
  tenantId: string;
  // Translation
  translates: any = {};

  constructor(
    private translate: TranslateService,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router
  ) {
    this.translate.stream(this.stringToTranslate).subscribe((translations) => {
      // Messages translates
      this.translates.notAvailableAbbreviation =
        translations["GENERICS.NOT_AVAILABLE_ABBREVIATION"];
      this.translates.genericsHouse = translations["GENERICS.HOUSE"];
      this.translates.houseNumber = translations["HOUSE.NUMBER"];
    });
  }

  ngOnInit() {
    this.hashCode = this.activatedRoute.snapshot.params.hashCode;
    this.tenantId = this.activatedRoute.snapshot.params.company;

    this.qrValue = window.location.href + "/";
    this.getRequests();
  }

  getRequests() {
    this.requestService.getRequestsByHashCode(this.tenantId,this.hashCode).subscribe(
      (success) => {
        if(success.length <= 0) {
          this.router.navigate([""]);
        }
        this.requestList = success;
        this.stilyzeNullsProperties();
        this.house = {
          description: success[0].houseDescription,
          number: success[0].houseNumber
        }
        this.sortMainTableByDefault();
      },
      (errorResponse) => {
        console.error(errorResponse);
        this.router.navigate([""]);
      }
    );
  }

  stilyzeNullsProperties () {
    this.requestList.forEach(e => {
      e.description = e.description || this.translates.notAvailableAbbreviation;
      e.location = e.location || this.translates.notAvailableAbbreviation;
      e.materialFamily = e.materialFamily || this.translates.notAvailableAbbreviation;
      e.reference = e.reference || this.translates.notAvailableAbbreviation;
      e.requestType = e.requestType || this.translates.notAvailableAbbreviation;
    });
  }

  // Ordena la tabla principal por los campos indicados.
  // Hecho de esta manera porque si se establecen los atributos en el html, no funciona.
  // Podría ser porque las cabeceras se cargan antes que los datos y luego no se ejecuta el order de manera automática...
  sortMainTableByDefault() {
    const multiSortMeta = [];
    multiSortMeta.push({ field: "requestType", order: -1 });
    multiSortMeta.push({ field: "location", order: -1 });
    multiSortMeta.push({ field: "materialFamily", order: -1 });
    this.requestListTable.sortMode = "multiple";
    this.requestListTable.multiSortMeta = multiSortMeta;
  }

  // Devuelve el nombre que se le pondrá al pdf que se imprime.
  getTitlePrint(): string {
    const date = new Date();
    const currentDateAsString = this.datepipe.transform(date, "ddMMyyhhmmss");
    if (
      null == this.house ||
      null == this.house.number ||
      null == this.house.description
    ) {
      return;
    }

    return (
      this.translates.genericsHouse +
      "_" +
      this.translates.houseNumber +
      "_" +
      this.house.number +
      "_" +
      this.house.description +
      "_" +
      currentDateAsString
    );
  }
}
