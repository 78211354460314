import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from 'src/app/services/survey/survey.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
  saveError : boolean  = false;
  constructor(private surveyService : SurveyService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.saveSurvey();
  }

  saveSurvey(){
    let company = this.activatedRoute.snapshot.params.company;
    let satisfaction = this.activatedRoute.snapshot.params.satisfaction;
    let idIncidence = this.activatedRoute.snapshot.params.idIncidence;
    let type = this.activatedRoute.snapshot.params.type;
    let username = this.activatedRoute.snapshot.params.username;
    this.surveyService.saveSurvey(company, satisfaction, idIncidence, type, username).subscribe((response)=>{
      if(!response){
      this.saveError = true; 
      }
    });
  }

}
