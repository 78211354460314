import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  constructor(private title: Title) { }

  ngOnInit() {
    // Change title webSite
    this.title.setTitle('MV_Armilla - Error Page');


  }

  ngOnDestroy() {
    // Change title webSite
    this.title.setTitle('MV_Armilla');
  }
}
